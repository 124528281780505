import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import moment from "moment";

import VueHtmlToPaper from "vue-html-to-paper";

// Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
