<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Search Game or Ticket</p>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class=""
        style="font-size: 13px"
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="$store.state.day_block > 0">
          <div v-if="$store.state.whichToShow == 'keno'">
            <div
              v-if="!pageLoading"
              class="white pa-10 pl-1 pr-1"
              id="report"
              style="border-radius: 3px"
            >
              <v-layout row wrap justify-space-around>
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h3 class="mt-6 grey--text text--darken-1 ml-10">
                      Search Keno Game result
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
              <v-form @submit.prevent="" id="chooseDate">
                <v-layout
                  row
                  wrap
                  class="mb-15 pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <v-flex xs8 md10 class="ma-1 pt-3">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="gameNumber"
                      :rules="[(v) => !!v || 'Required']"
                      label="Search by Game Number"
                      required
                      dense
                      outlined
                      append-icon="search"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 md1 class="ma-1 pt-4">
                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="searchGame"
                      :loading="gameSearchLoading"
                    >
                      <span class="text-capitalize">Go</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 v-if="searchedGame != ''" class="pa-1">
                    <span class="grey--text text--darken-1"
                      >Game Number:
                      <strong>{{ searchedGame.game_number }}</strong> </span
                    ><br />
                    <span
                      class="grey--text text--darken-1"
                      style="font-size: 12px"
                      >Drawn Numbers:
                      <strong>{{ searchedGame.drawn_numbers }}</strong>
                    </span>
                    <br />
                    <span class="grey--text text--darken-1"
                      >Other drawn:
                      <strong
                        >{{ searchedGame.high_low }},
                        {{ searchedGame.even_odd }}</strong
                      >
                    </span>
                    <br />
                    <hr />
                    <br />
                    <v-flex v-for="cashier in allCashiers" :key="cashier">
                      <span>
                        <strong>Cashier Name: {{ cashier }}</strong> </span
                      ><br />
                      <span
                        v-for="ticket in searchedGame.getTickets"
                        :key="ticket.ticket_id"
                      >
                        <span
                          v-if="
                            cashier == ticket.getCashier[0].cashier_full_name
                          "
                        >
                          Ticket:{{ ticket.choosen_numbers }} <br />
                          Others:{{ ticket.others }} <br />
                          User Id:{{ ticket.user_id }} <br />
                        </span>
                      </span>
                      <br />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-form>

              <v-layout row wrap justify-space-around>
                <v-flex xs12 md6>
                  <div id="chooseDate" class="mr-10 pl-7">
                    <h3 class="mt-6 grey--text text--darken-1">
                      Search ticket using User Id or Barcode
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
              <v-form @submit.prevent="" id="chooseDate">
                <v-layout
                  row
                  wrap
                  class="mb-15 pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <v-flex xs8 md10 class="ma-1 pt-3">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="userId"
                      :rules="[(v) => !!v || 'Required']"
                      label="Search Ticket"
                      required
                      dense
                      outlined
                      append-icon="search"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 md1 class="ma-1 pt-4">
                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="searchTicket"
                      :loading="ticketSearchLoading"
                    >
                      <span class="text-capitalize">Go</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs12 v-if="searchedTicket != ''" class="pa-2">
                    <div style="color: black">
                      <div style="">
                        <span>
                          Given by:
                          {{ searchedTicket.getCashier[0].cashier_full_name }}
                        </span>
                        <br />
                        <span
                          >Date:
                          {{
                            new Date(Number(searchedTicket.ticket_date))
                              | formatDate
                          }}</span
                        ><br />
                        <span v-if="searchedTicket.getGame.length > 0"
                          >Game#:
                          {{ searchedTicket.getGame[0].game_number }}</span
                        >
                        <br />

                        <span
                          class="red--text"
                          v-if="searchedTicket.is_cancelled == 1"
                          >Ticket cancelled by:
                          {{
                            searchedTicket.getCashier[0].cashier_full_name
                          }}</span
                        >

                        <span
                          class="green--text"
                          v-else-if="
                            searchedTicket.winner_award == 0 &&
                            totalWinMoneyForOneUser != 0
                          "
                          >User won: {{ totalWinMoneyForOneUser }} Birr
                        </span>

                        <span
                          class="red--text"
                          v-else-if="
                            searchedTicket.winner_award == 0 &&
                            totalWinMoneyForOneUser == 0
                          "
                          >User lost the game
                        </span>

                        <span
                          class="green--text"
                          v-else-if="searchedTicket.winner_award != 0"
                          >{{ searchedTicket.winner_award }} Birr Paid by:
                          {{
                            searchedTicket.getCashier[0].cashier_full_name
                          }}</span
                        >
                        <br />
                      </div>
                      <hr style="border-top: 1px dashed" />
                      <br />

                      <h4>Choosen Numbers</h4>

                      <span style="font-weight: bold">
                        {{ searchedTicket.choosen_numbers }}
                      </span>
                      <br />
                      <span style="font-weight: bold">
                        {{ searchedTicket.others }}
                      </span>

                      <br />
                      <br />
                      <h4>Game Result</h4>

                      <div class="mr-1">
                        <span
                          v-if="searchedTicket.getGame.length > 0"
                          class="grey--text text--darken-1"
                          style="font-size: 12px"
                        >
                          Drawn Numbers:
                          <strong>{{
                            searchedTicket.getGame[0].drawn_numbers
                          }}</strong>
                        </span>
                        <br />
                        <span
                          class="grey--text text--darken-1"
                          v-if="searchedTicket.getGame.length > 0"
                          >Other drawn:
                          <strong
                            >{{ searchedTicket.getGame[0].high_low }},
                            {{ searchedTicket.getGame[0].even_odd }}</strong
                          >
                        </span>
                      </div>
                    </div>

                    <div style="">
                      <barcode :value="searchedTicket.user_id" height="90px">
                        Some thing went wrong.
                      </barcode>
                    </div>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-layout row wrap id="chooseDate">
                <v-flex xs12 class="text-center">
                  <v-btn
                    :loading="cancelledTicketsLoading"
                    class="pl-5 pr-5 orange darken-3"
                    text
                    dark
                    small
                    @click="getCancelledTickets"
                  >
                    <Span class="text-capitalize">Cancelled Tickets</Span>
                  </v-btn>
                </v-flex>
                <v-flex
                  class="mt-10"
                  xs12
                  v-for="canceledTicket in cancelledTickets"
                  :key="canceledTicket.ticket_id"
                >
                  <span v-if="canceledTicket.getGame.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGame[0].game_number }}</span
                  ><br />
                  <span v-if="canceledTicket.getGame.length > 0"
                    >Drawn Numbers:
                    {{ canceledTicket.getGame[0].drawn_numbers }}</span
                  ><br />
                  <span v-if="canceledTicket.getGame.length > 0"
                    >High/Low: {{ canceledTicket.getGame[0].high_low }}</span
                  ><br />
                  <span v-if="canceledTicket.getGame.length > 0"
                    >Even/Odd: {{ canceledTicket.getGame[0].even_odd }}</span
                  ><br />
                  <span>User ID: {{ canceledTicket.user_id }}</span
                  ><br />
                  <span>Ticket: {{ canceledTicket.choosen_numbers }}</span
                  ><br />
                  <span>Others: {{ canceledTicket.others }}</span
                  ><br />
                  <span>
                    Cancelled By:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                  <br /><br />
                </v-flex>

                <v-flex xs12 class="text-center mt-6 mb-6">
                  <v-btn
                    :loading="duplicatedTicketsLoading"
                    class="pl-5 pr-5 red darken-3"
                    text
                    dark
                    small
                    @click="getDuplicatedTickets"
                  >
                    <Span class="text-capitalize">Duplicated Tickets</Span>
                  </v-btn>
                </v-flex>
                <v-flex
                  class="mt-2 mb-1"
                  xs12
                  v-for="(canceledTicket, i) in duplicatedTickets"
                  :key="canceledTicket.ticket_id"
                >
                  <v-divider v-if="i != 0"> </v-divider>
                  <span v-if="canceledTicket.getGameForShifts.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGameForShifts[0].game_number }}</span
                  ><br />

                  <span>User ID: {{ canceledTicket.user_id }}</span
                  ><br />
                  <span class="red--text"
                    >Ticket: {{ canceledTicket.choosen_numbers }}</span
                  ><br />
                  <span class="red--text"
                    >Others: {{ canceledTicket.others }}</span
                  ><br />
                  <span>
                    Cashier:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                </v-flex>

                <v-flex xs12 class="text-center mb-10">
                  <v-btn
                    :loading="replacedTicketsLoading"
                    class="pl-5 pr-5 green darken-3"
                    text
                    dark
                    small
                    @click="getReplacedTickets"
                  >
                    <Span class="text-capitalize">Replaced Tickets</Span>
                  </v-btn>
                </v-flex>
                <v-flex
                  class="mt-2 mb-1"
                  xs12
                  v-for="(canceledTicket, i) in replacedTickets"
                  :key="canceledTicket.ticket_id"
                >
                  <v-divider v-if="i != 0"> </v-divider>

                  <span
                    class="red--text"
                    v-if="canceledTicket.getGameForShifts.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGameForShifts[0].game_number }}</span
                  ><br />

                  <span class="red--text"
                    >User ID: {{ canceledTicket.getReplaced[0].user_id }}</span
                  ><br />
                  <span class="red--text"
                    >Ticket:
                    {{ canceledTicket.getReplaced[0].choosen_numbers }}</span
                  ><br />
                  <span class="red--text"
                    >Others: {{ canceledTicket.getReplaced[0].others }}</span
                  ><br />
                  <span class="red--text">
                    Cashier:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                  <br />
                  <span
                    class="pa-4"
                    v-if="canceledTicket.getGameForShifts.length > 0"
                  >
                    <strong>Replaced By:</strong> </span
                  ><br />

                  <span
                    class="green--text"
                    v-if="canceledTicket.getGameForShifts.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGameForShifts[0].game_number }}</span
                  ><br />

                  <span class="green--text"
                    >User ID: {{ canceledTicket.user_id }}</span
                  ><br />
                  <span class="green--text"
                    >Ticket: {{ canceledTicket.choosen_numbers }}</span
                  ><br />
                  <span class="green--text"
                    >Others: {{ canceledTicket.others }}</span
                  ><br />
                  <span class="green--text">
                    Cashier:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                </v-flex>
              </v-layout>
            </div>
          </div>

          <div v-if="$store.state.whichToShow == 'spin'">
            <div
              v-if="!pageLoading"
              class="white pa-10 pl-1 pr-1"
              id="report"
              style="border-radius: 3px"
            >
              <v-layout row wrap justify-space-around>
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h3 class="mt-6 grey--text text--darken-1 ml-10">
                      Search spin Game result
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
              <v-form @submit.prevent="" id="chooseDate">
                <v-layout
                  row
                  wrap
                  class="mb-15 pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <v-flex xs8 md10 class="ma-1 pt-3">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinGameNumber"
                      :rules="[(v) => !!v || 'Required']"
                      label="Search by Game Number"
                      required
                      dense
                      outlined
                      append-icon="search"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 md1 class="ma-1 pt-4">
                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="spinSearchGame"
                      :loading="spinGameSearchLoading"
                    >
                      <span class="text-capitalize">Go</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 v-if="spinSearchedGame != ''" class="pa-1">
                    <span class="grey--text text--darken-1"
                      >Game Number:
                      <strong>{{ spinSearchedGame.game_number }}</strong> </span
                    ><br />
                    <span
                      class="grey--text text--darken-1"
                      style="font-size: 12px"
                      >Drawn Numbers:
                      <strong>{{ spinSearchedGame.drawn_numbers }}</strong>
                    </span>
                    <br />
                    <span class="grey--text text--darken-1"
                      >Other drawn:
                      <strong></strong>
                      {{
                        spinSearchedGame.getSpinNumber[0].color +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].mirror +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].twins +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].final +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].dozen +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].sector +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].combination +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].even_odd +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].high_low +
                        ", " +
                        spinSearchedGame.getSpinNumber[0].neighbors
                      }}
                    </span>
                    <br />
                    <hr />
                    <br />
                    <v-flex v-for="cashier in spinAllCashiers" :key="cashier">
                      <span>
                        <strong>Cashier Name: {{ cashier }}</strong> </span
                      ><br />
                      <span
                        v-for="ticket in spinSearchedGame.getTickets"
                        :key="ticket.ticket_id"
                      >
                        <span
                          v-if="
                            cashier == ticket.getCashier[0].cashier_full_name
                          "
                        >
                          Ticket:{{ ticket.choosen_numbers }} <br />

                          User Id:{{ ticket.user_id }} <br />
                        </span>
                      </span>
                      <br />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-form>

              <v-layout row wrap justify-space-around>
                <v-flex xs12 md6>
                  <div id="chooseDate" class="mr-10 pl-7">
                    <h3 class="mt-6 grey--text text--darken-1">
                      Search ticket using User Id or Barcode
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
              <v-form @submit.prevent="" id="chooseDate">
                <v-layout
                  row
                  wrap
                  class="mb-15 pa-3 pt-0"
                  style="font-size: 14px"
                  justify-space-around
                >
                  <v-flex xs8 md10 class="ma-1 pt-3">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="spinUserId"
                      :rules="[(v) => !!v || 'Required']"
                      label="Search Ticket"
                      required
                      dense
                      outlined
                      append-icon="search"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 md1 class="ma-1 pt-4">
                    <v-btn
                      small
                      text
                      class="primary"
                      type="submit"
                      @click="spinSearchTicket"
                      :loading="spinTicketSearchLoading"
                    >
                      <span class="text-capitalize">Go</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs12 v-if="spinSearchedTicket != ''" class="pa-2">
                    <div style="color: black">
                      <div style="">
                        <span>
                          Given by:
                          {{
                            spinSearchedTicket.getCashier[0].cashier_full_name
                          }}
                        </span>
                        <br />
                        <span
                          >Date:
                          {{
                            new Date(Number(spinSearchedTicket.ticket_date))
                              | formatDate
                          }}</span
                        ><br />
                        <span v-if="spinSearchedTicket.getGame.length > 0"
                          >Game#:
                          {{ spinSearchedTicket.getGame[0].game_number }}</span
                        >
                        <br />

                        <span
                          class="red--text"
                          v-if="spinSearchedTicket.is_cancelled == 1"
                          >Ticket cancelled by:
                          {{
                            spinSearchedTicket.getCashier[0].cashier_full_name
                          }}</span
                        >

                        <span
                          class="red--text"
                          v-else-if="
                            spinSearchedTicket.winner_award == 0 &&
                            spinTotalWinMoneyForOneUser == 0
                          "
                          >User Lost the game</span
                        >

                        <span
                          class="green--text"
                          v-else-if="
                            spinSearchedTicket.winner_award == 0 &&
                            spinTotalWinMoneyForOneUser != 0
                          "
                          >User won:
                          {{ spinTotalWinMoneyForOneUser }} Birr</span
                        >

                        <span
                          class="green--text"
                          v-else-if="spinSearchedTicket.winner_award != 0"
                          >{{ spinSearchedTicket.winner_award }} Birr Paid by:
                          {{
                            spinSearchedTicket.getCashier[0].cashier_full_name
                          }}</span
                        >
                        <br />
                      </div>
                      <hr style="border-top: 1px dashed" />
                      <br />

                      <h4>Choosen Numbers</h4>

                      <span style="font-weight: bold">
                        {{ spinSearchedTicket.choosen_numbers }}
                      </span>

                      <br />
                      <br />
                      <h4>Game Result</h4>

                      <div class="mr-1">
                        <span
                          v-if="spinSearchedTicket.getGame.length > 0"
                          class="grey--text text--darken-1"
                          style="font-size: 12px"
                        >
                          Drawn Numbers:
                          <strong>{{
                            spinSearchedTicket.getGame[0].drawn_numbers
                          }}</strong>
                        </span>
                        <br />
                        <span
                          class="grey--text text--darken-1"
                          v-if="spinSearchedTicket.getGame.length > 0"
                          >Other drawn:
                          <strong>
                            {{
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .color +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .mirror +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .twins +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .final +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .dozen +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .sector +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .combination +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .even_odd +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .high_low +
                              ", " +
                              spinSearchedTicket.getGame[0].getSpinNumber[0]
                                .neighbors
                            }}</strong
                          >
                        </span>
                      </div>
                    </div>

                    <div style="">
                      <barcode
                        :value="spinSearchedTicket.user_id"
                        height="90px"
                      >
                        Some thing went wrong.
                      </barcode>
                    </div>
                  </v-flex>
                </v-layout>
              </v-form>
              <v-layout row wrap id="chooseDate">
                <v-flex xs12 class="text-center">
                  <v-btn
                    :loading="spinCancelledTicketsLoading"
                    class="pl-5 pr-5 orange darken-3"
                    text
                    dark
                    small
                    @click="spinGetCancelledTickets"
                  >
                    <Span class="text-capitalize">Cancelled Tickets</Span>
                  </v-btn>
                </v-flex>
                <v-flex
                  class="mt-10"
                  xs12
                  v-for="canceledTicket in spinCancelledTickets"
                  :key="canceledTicket.ticket_id"
                >
                  <span v-if="canceledTicket.getGame.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGame[0].game_number }}</span
                  ><br />
                  <span v-if="canceledTicket.getGame.length > 0"
                    >Drawn Numbers:
                    {{ canceledTicket.getGame[0].drawn_numbers }}</span
                  ><br />
                  <span v-if="canceledTicket.getGame.length > 0"
                    >Others:
                    {{
                      canceledTicket.getGame[0].getSpinNumber[0].number +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].color +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].mirror +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].twins +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].final +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].dozen +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].sector +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].combination +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].even_odd +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].high_low +
                      ", " +
                      canceledTicket.getGame[0].getSpinNumber[0].neighbors
                    }}</span
                  >
                  <br />
                  <span>User ID: {{ canceledTicket.user_id }}</span
                  ><br />
                  <span>Ticket: {{ canceledTicket.choosen_numbers }}</span
                  ><br />

                  <br />
                  <span>
                    Cancelled By:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                  <br /><br />
                </v-flex>
                <v-flex xs12 class="text-center mt-6 mb-6">
                  <v-btn
                    :loading="duplicatedTicketsLoading"
                    class="pl-5 pr-5 red darken-3"
                    text
                    dark
                    small
                    @click="spinGetDuplicatedTickets"
                  >
                    <Span class="text-capitalize">Duplicated Tickets</Span>
                  </v-btn>
                </v-flex>
                <v-flex
                  class="mt-2 mb-1"
                  xs12
                  v-for="(canceledTicket, i) in spinDuplicatedTickets"
                  :key="canceledTicket.ticket_id"
                >
                  <v-divider v-if="i != 0"> </v-divider>
                  <span v-if="canceledTicket.getGameForShifts.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGameForShifts[0].game_number }}</span
                  ><br />

                  <span>User ID: {{ canceledTicket.user_id }}</span
                  ><br />
                  <span class="red--text"
                    >Ticket: {{ canceledTicket.choosen_numbers }}</span
                  ><br />

                  <span>
                    Cashier:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                </v-flex>
                <v-flex xs12 class="text-center mb-10">
                  <v-btn
                    :loading="replacedTicketsLoading"
                    class="pl-5 pr-5 green darken-3"
                    text
                    dark
                    small
                    @click="spinGetReplacedTickets"
                  >
                    <Span class="text-capitalize">Replaced Tickets</Span>
                  </v-btn>
                </v-flex>
                <v-flex
                  class="mt-2 mb-1"
                  xs12
                  v-for="(canceledTicket, i) in spinReplacedTickets"
                  :key="canceledTicket.ticket_id"
                >
                  <v-divider v-if="i != 0"> </v-divider>

                  <span
                    class="red--text"
                    v-if="canceledTicket.getGameForShifts.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGameForShifts[0].game_number }}</span
                  ><br />

                  <span class="red--text"
                    >User ID: {{ canceledTicket.getReplaced[0].user_id }}</span
                  ><br />
                  <span class="red--text"
                    >Ticket:
                    {{ canceledTicket.getReplaced[0].choosen_numbers }}</span
                  ><br />
                  <span class="red--text"
                    >Others: {{ canceledTicket.getReplaced[0].others }}</span
                  ><br />
                  <span class="red--text">
                    Cashier:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                  <br />
                  <span
                    class="pa-4"
                    v-if="canceledTicket.getGameForShifts.length > 0"
                  >
                    <strong>Replaced By:</strong> </span
                  ><br />

                  <span
                    class="green--text"
                    v-if="canceledTicket.getGameForShifts.length > 0"
                    >Game Number:
                    {{ canceledTicket.getGameForShifts[0].game_number }}</span
                  ><br />

                  <span class="green--text"
                    >User ID: {{ canceledTicket.user_id }}</span
                  ><br />
                  <span class="green--text"
                    >Ticket: {{ canceledTicket.choosen_numbers }}</span
                  ><br />
                  <span class="green--text"
                    >Others: {{ canceledTicket.others }}</span
                  ><br />
                  <span class="green--text">
                    Cashier:
                    {{ canceledTicket.getCashier[0].cashier_full_name }}</span
                  >
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
        <div v-else class="mt-16">
          <p v-if="!pageLoading" class="headline red--text text-center">
            Reachs maximum day, Please contact the admin
          </p>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import VueBarcode from "vue-barcode";

export default {
  components: {
    barcode: VueBarcode,
  },
  data() {
    return {
      pageLoading: false,
      gameSearchLoading: false,
      ticketSearchLoading: false,
      loadingCount: 0,
      gameNumber: "",
      userId: "",
      searchedGame: "",
      searchedTicket: "",
      dailyWork: [],
      allCashiers: [],
      cancelledTickets: [],
      cancelledTicketsLoading: false,
      odds: [],
      spinOdds: [],
      spinNumbers: [],
      verifyUserData: [],
      spinVerifyUserData: [],
      totalWinMoneyForOneUser: 0,
      spinTotalWinMoneyForOneUser: 0,
      spinGameSearchLoading: false,
      spinTicketSearchLoading: false,

      spinGameNumber: "",
      spinUserId: "",
      spinSearchedGame: "",
      spinSearchedTicket: "",

      spinAllCashiers: [],
      spinCancelledTickets: [],
      spinCancelledTicketsLoading: false,

      duplicatedTicketsLoading: false,
      replacedTicketsLoading: false,
      duplicatedTickets: [],
      spinDuplicatedTickets: [],

      replacedTickets: [],
      spinReplacedTickets: [],
    };
  },

  methods: {
    async getCancelledTickets() {
      this.cancelledTicketsLoading = true;
      try {
        var cancelledTicketsResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                 getCancelledTickets{
                    ticket_id
                    game_id
                    user_id
                    cashier_id
                    partner_id
                    choosen_numbers
                    others
                    ticket_date
                    winner_award
                     getGame{
                          game_number
                          drawn_numbers
                          high_low     
                          even_odd
                          game_status
                        
                        }
                    getCashier{
                      cashier_full_name
                    }
                  }
              
              
              }
              `,
          },
        });

        this.cancelledTickets =
          cancelledTicketsResult.data.data.getCancelledTickets;
      } catch (err) {
        alert(err);
      }

      this.cancelledTicketsLoading = false;
    },

    async getDuplicatedTickets() {
      this.duplicatedTicketsLoading = true;
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() -
        (48 * 60 * 60 * 1000 + 8683989);
      try {
        var duplicatedResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
              getDailyWorksDuplicated(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      cashier_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getCashier{
                      cashier_full_name
                    }
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });

        var lastGame = duplicatedResult.data.data.getDailyWorksDuplicated;
        this.duplicatedTickets = [];
        if (lastGame.length > 0) {
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id &&
                  lastGame[j - 1].game_id == ticket.game_id
                ) {
                  if (this.duplicatedTickets.indexOf(lastGame[j - 1]) == -1)
                    this.duplicatedTickets.push(lastGame[j - 1]);

                  if (this.duplicatedTickets.indexOf(ticket) == -1)
                    this.duplicatedTickets.push(ticket);
                }

                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id &&
                  lastGame[j + 1].game_id == ticket.game_id
                ) {
                  if (this.duplicatedTickets.indexOf(ticket) == -1)
                    this.duplicatedTickets.push(ticket);

                  if (this.duplicatedTickets.indexOf(lastGame[j + 1]) == -1)
                    this.duplicatedTickets.push(lastGame[j + 1]);
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id &&
                  lastGame[j - 1].game_id == ticket.game_id
                ) {
                  if (this.duplicatedTickets.indexOf(lastGame[j - 1]) == -1)
                    this.duplicatedTickets.push(lastGame[j - 1]);

                  if (this.duplicatedTickets.indexOf(ticket) == -1)
                    this.duplicatedTickets.push(ticket);
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id &&
                  lastGame[j + 1].game_id == ticket.game_id
                ) {
                  if (this.duplicatedTickets.indexOf(ticket) == -1)
                    this.duplicatedTickets.push(ticket);

                  if (this.duplicatedTickets.indexOf(lastGame[j + 1]) == -1)
                    this.duplicatedTickets.push(lastGame[j + 1]);
                }
              }
            }
          }
        }
      } catch (err) {
        alert(err);
      }

      this.duplicatedTicketsLoading = false;
    },

    async getReplacedTickets() {
      this.replacedTicketsLoading = true;
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() -
        (24 * 60 * 60 * 1000 + 8683989);
      try {
        var replacedResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
              getReplacedTickets(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      cashier_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getReplaced{
                        ticket_id
                      cashier_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      }
                      getCashier{
                      cashier_full_name
                    }
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });

        this.replacedTickets = replacedResult.data.data.getReplacedTickets;
      } catch (err) {
        alert(err);
      }

      this.replacedTicketsLoading = false;
    },

    async spinGetReplacedTickets() {
      this.replacedTicketsLoading = true;
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() -
        (24 * 60 * 60 * 1000 + 8683989);
      try {
        var replacedResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
              getReplacedTickets(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      cashier_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getReplaced{
                        ticket_id
                      cashier_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      }
                      getCashier{
                      cashier_full_name
                    }
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });

        this.spinReplacedTickets = replacedResult.data.data.getReplacedTickets;
      } catch (err) {
        alert(err);
      }

      this.replacedTicketsLoading = false;
    },
    async spinGetDuplicatedTickets() {
      this.duplicatedTicketsLoading = true;
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() -
        (48 * 60 * 60 * 1000 + 8683989);
      try {
        var duplicatedResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
              getDailyWorksDuplicated(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      cashier_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      ticket_number
                      getCashier{
                      cashier_full_name
                    }
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });

        var lastGame = duplicatedResult.data.data.getDailyWorksDuplicated;
        this.spinDuplicatedTickets = [];
        if (lastGame.length > 0) {
          for (let j = 0; j < lastGame.length; j++) {
            var ticket = lastGame[j];

            if (j != 0) {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id &&
                  lastGame[j - 1].game_id == ticket.game_id
                ) {
                  if (this.spinDuplicatedTickets.indexOf(lastGame[j - 1]) == -1)
                    this.spinDuplicatedTickets.push(lastGame[j - 1]);

                  if (this.spinDuplicatedTickets.indexOf(ticket) == -1)
                    this.spinDuplicatedTickets.push(ticket);
                }

                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id &&
                  lastGame[j + 1].game_id == ticket.game_id
                ) {
                  if (this.spinDuplicatedTickets.indexOf(ticket) == -1)
                    this.spinDuplicatedTickets.push(ticket);

                  if (this.spinDuplicatedTickets.indexOf(lastGame[j + 1]) == -1)
                    this.spinDuplicatedTickets.push(lastGame[j + 1]);
                }
              } else {
                if (
                  lastGame[j - 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j - 1].others == ticket.others &&
                  lastGame[j - 1].cashier_id == ticket.cashier_id &&
                  lastGame[j - 1].game_id == ticket.game_id
                ) {
                  if (this.spinDuplicatedTickets.indexOf(lastGame[j - 1]) == -1)
                    this.spinDuplicatedTickets.push(lastGame[j - 1]);

                  if (this.spinDuplicatedTickets.indexOf(ticket) == -1)
                    this.spinDuplicatedTickets.push(ticket);
                }
              }
            } else {
              if (lastGame.length != j + 1) {
                if (
                  lastGame[j + 1].choosen_numbers == ticket.choosen_numbers &&
                  lastGame[j + 1].others == ticket.others &&
                  lastGame[j + 1].cashier_id == ticket.cashier_id &&
                  lastGame[j + 1].game_id == ticket.game_id
                ) {
                  if (this.spinDuplicatedTickets.indexOf(ticket) == -1)
                    this.spinDuplicatedTickets.push(ticket);

                  if (this.spinDuplicatedTickets.indexOf(lastGame[j + 1]) == -1)
                    this.spinDuplicatedTickets.push(lastGame[j + 1]);
                }
              }
            }
          }
        }
      } catch (err) {
        alert(err);
      }

      this.duplicatedTicketsLoading = false;
    },
    async searchGame() {
      if (this.gameNumber != "") {
        this.gameSearchLoading = true;

        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `{
               searchGameByGameNumber(game_number:"${this.gameNumber}") {
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
                      even_odd
                      game_status
                      getTickets {
                        ticket_id
                        game_id
                        user_id
                        choosen_numbers
                        others
                        cashier_id
                        ticket_date
                        winner_award
                        is_cancelled
                        getCashier{
                            cashier_id
                            cashier_full_name
                            
                          }
                      }
                    }
              
              }
              `,
            },
          });
          this.allCashiers = [];

          if (gameResult.data.data.searchGameByGameNumber.length > 0) {
            this.searchedGame = gameResult.data.data.searchGameByGameNumber[0];

            for (let i = 0; i < this.searchedGame.getTickets.length; i++) {
              var cashier =
                this.searchedGame.getTickets[i].getCashier[0].cashier_full_name;
              if (this.allCashiers.indexOf(cashier) === -1)
                this.allCashiers.push(cashier);
            }
          } else alert("Game not found");
        } catch (err) {
          alert(err);
        }

        // var date2 = "";
        // var date1 = "";

        // date2 = Date.now();
        // date1 =
        //   new Date(this.formatDate(new Date(Date.now()))).getTime() - 8683989;

        // try {
        //   var gameAnalysisResult = await axios({
        //     method: "POST",
        //     url: this.$store.state.adminURL,

        //     data: {
        //       query: `{
        //        getTodaysGameAnalysis(date1:"${date1}", date2:"${date2}"){
        //            game_id
        //            cashier_id
        //            partner_id
        //            game_number
        //            drawn_numbers
        //            game_date
        //            high_low
        //            even_odd
        //            game_status
        //            getTickets {
        //              ticket_id
        //              game_id
        //              user_id
        //              choosen_numbers
        //              others
        //              ticket_date
        //              winner_award
        //              is_cancelled
        //            }

        //       }

        //       }
        //       `,
        //     },
        //   });
        //   this.dailyWork = gameAnalysisResult.data.data.getTodaysGameAnalysis;
        // } catch (err) {
        //   alert(err);
        // }
        this.gameSearchLoading = false;
      } else alert("Please enter game number");
    },

    async searchTicket() {
      if (this.userId != "") {
        this.ticketSearchLoading = true;

        try {
          var ticketResult = await axios({
            method: "POST",
            url: this.$store.state.partnerURL,

            data: {
              query: `{
                searchTicketByUserId(user_id:"${this.userId}"){
                        ticket_id
                        game_id
                        user_id
                        cashier_id    
                        choosen_numbers
                        others
                        ticket_date
                        winner_award
                        is_cancelled
                          getCashier{
                            cashier_full_name
                            cashier_id
                            
                          }
                        getGame{
                          game_number
                          drawn_numbers
                          high_low     
                          even_odd
                          game_status
                        
                        }
    
    
                      }
              
              
              }
              `,
            },
          });
          var totalWinOtherMoney = 0;
          var totalWinMoney = 0;
          if (ticketResult.data.data.searchTicketByUserId.length > 0) {
            this.verifyUserData = ticketResult.data.data.searchTicketByUserId;
            this.searchedTicket =
              ticketResult.data.data.searchTicketByUserId[0];

            var tickets = this.verifyUserData[0].choosen_numbers.split(":");
            var others = this.verifyUserData[0].others.split(":");
            if (others[0] != "" && this.verifyUserData[0].getGame.length > 0) {
              for (let r = 0; r < others.length; r++) {
                if (
                  this.verifyUserData[0].getGame[0].high_low.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase() ||
                  this.verifyUserData[0].getGame[0].even_odd.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                ) {
                  for (let x = 0; x < this.odds.length; x++) {
                    if (
                      this.odds[x].choosen_length.toLowerCase() ==
                      others[r].split("*")[0].trim().toLowerCase()
                    ) {
                      // this.payableList.push(
                      //   others[r] +
                      //     " = " +
                      //     parseFloat(
                      //       this.$store.state.odds[x].odd_value
                      //     ) *
                      //       parseFloat(
                      //         others[r].split("*")[1].trim()
                      //       ).toString() +
                      //     " Birr Won "
                      // );
                      totalWinOtherMoney =
                        totalWinOtherMoney +
                        parseFloat(this.odds[x].odd_value) *
                          parseFloat(others[r].split("*")[1].trim());
                    }
                  }
                }
              }
            }

            if (tickets[0] != "" && this.verifyUserData[0].getGame.length > 0) {
              for (let i = 0; i < tickets.length; i++) {
                let countWin = 0;
                var eachNumber = tickets[i].split("*")[0].split(",");
                var eachAmount = parseFloat(tickets[i].split("*")[1]);

                var matchCount = 0;
                var drownNumbers =
                  this.verifyUserData[0].getGame[0].drawn_numbers.split(",");
                for (let k = 0; k < eachNumber.length; k++) {
                  for (let j = 0; j < drownNumbers.length; j++) {
                    if (drownNumbers[j] == eachNumber[k].trim()) {
                      matchCount++;
                    }
                  }
                }

                for (let x = 0; x < this.odds.length; x++) {
                  if (
                    parseInt(this.odds[x].choosen_length) == eachNumber.length
                  ) {
                    var hits = this.odds[x].hits.split(",");
                    var possibleWinMoney = this.odds[x].odd_value.split(",");

                    for (let y = 0; y < hits.length; y++) {
                      if (matchCount == parseInt(hits[y].trim())) {
                        countWin++;
                        // this.payableList.push(
                        //   tickets[i] +
                        //     " = " +
                        //     (
                        //       (parseFloat(possibleWinMoney[y].trim()) *
                        //         eachAmount) /
                        //       10
                        //     ).toString() +
                        //     " Birr Won"
                        // );
                        totalWinMoney =
                          totalWinMoney +
                          parseFloat(possibleWinMoney[y].trim()) * eachAmount;
                      }
                    }
                  }
                }

                // if (countWin == 0)
                //   this.payableList.push(tickets[i] + " = Loss ");
              }
            }

            this.totalWinMoneyForOneUser =
              totalWinMoney / 10 + totalWinOtherMoney;
          } else alert("Ticket not found");
        } catch (err) {
          alert(err);
        }
        this.ticketSearchLoading = false;
      } else alert("Please enter user id or scan bar code");
    },

    async spinGetCancelledTickets() {
      this.spinCancelledTicketsLoading = true;
      try {
        var cancelledTicketsResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
                 getCancelledTickets{
                    ticket_id
                    game_id
                    user_id
                    cashier_id
                    partner_id
                    choosen_numbers
                    others
                    ticket_date
                    winner_award
                     getGame{
                          game_number
                          drawn_numbers
                          high_low     
                          even_odd
                          game_status
                          getSpinNumber{
                            spin_number_id
                            number
                            color
                            mirror
                            twins
                            final
                            dozen
                            sector
                            combination
                            even_odd
                            high_low
                            neighbors
                          }
                        
                        }
                    getCashier{
                      cashier_full_name
                    }
                  }
              
              
              }
              `,
          },
        });

        this.spinCancelledTickets =
          cancelledTicketsResult.data.data.getCancelledTickets;
      } catch (err) {
        alert(err);
      }

      this.spinCancelledTicketsLoading = false;
    },
    async spinSearchGame() {
      if (this.spinGameNumber != "") {
        this.spinGameSearchLoading = true;

        try {
          var gameResult = await axios({
            method: "POST",
            url: this.$store.state.spinPartnerURL,

            data: {
              query: `{
               searchGameByGameNumber(game_number:"${this.spinGameNumber}") {
                      game_id
                      game_number
                      drawn_numbers
                      game_date
                      high_low
                      even_odd
                      game_status
                      getSpinNumber{
                            spin_number_id
                            number
                            color
                            mirror
                            twins
                            final
                            dozen
                            sector
                            combination
                            even_odd
                            high_low
                            neighbors
                          }
                      getTickets {
                        ticket_id
                        game_id
                        user_id
                        choosen_numbers
                        others
                        cashier_id
                        ticket_date
                        winner_award
                        is_cancelled
                        getCashier{
                            cashier_id
                            cashier_full_name
                            
                          }
                      }
                    }
              
              }
              `,
            },
          });
          this.spinAllCashiers = [];

          if (gameResult.data.data.searchGameByGameNumber.length > 0) {
            this.spinSearchedGame =
              gameResult.data.data.searchGameByGameNumber[0];

            for (let i = 0; i < this.spinSearchedGame.getTickets.length; i++) {
              var cashier =
                this.spinSearchedGame.getTickets[i].getCashier[0]
                  .cashier_full_name;
              if (this.spinAllCashiers.indexOf(cashier) === -1)
                this.spinAllCashiers.push(cashier);
            }
          } else alert("Game not found");
        } catch (err) {
          alert(err);
        }

        this.spinGameSearchLoading = false;
      } else alert("Please enter game number");
    },

    async spinSearchTicket() {
      if (this.spinUserId != "") {
        this.spinTicketSearchLoading = true;

        try {
          var ticketResult = await axios({
            method: "POST",
            url: this.$store.state.spinPartnerURL,

            data: {
              query: `{
                searchTicketByUserId(user_id:"${this.spinUserId}"){
                        ticket_id
                        game_id
                        user_id
                        cashier_id    
                        choosen_numbers
                        others
                        ticket_date
                        winner_award
                        is_cancelled
                          getCashier{
                            cashier_full_name
                            cashier_id
                            
                          }
                        getGame{
                          game_number
                          drawn_numbers
                          high_low     
                          even_odd
                          game_status
                          getSpinNumber{
                            spin_number_id
                            number
                            color
                            mirror
                            twins
                            final
                            dozen
                            sector
                            combination
                            even_odd
                            high_low
                            neighbors
                          }
                        
                        }
    
    
                      }
              
              
              }
              `,
            },
          });

          var totalPossibleWinMoney = 0;

          if (ticketResult.data.data.searchTicketByUserId.length > 0) {
            this.spinVerifyUserData =
              ticketResult.data.data.searchTicketByUserId;
            this.spinSearchedTicket =
              ticketResult.data.data.searchTicketByUserId[0];
            var tickets = this.spinVerifyUserData[0].choosen_numbers.split(":");
            var spinNum =
              this.spinVerifyUserData[0].getGame.length > 0
                ? this.spinVerifyUserData[0].getGame[0].getSpinNumber
                : [];
            var rowOut2 = this.spinOdds;
            if (
              tickets[0] != "" &&
              this.spinVerifyUserData[0].getGame.length > 0
            ) {
              for (let i = 0; i < tickets.length; i++) {
                let countWin = 0;
                var eachNumber = tickets[i].split("*")[0].trim();

                var eachAmount = parseFloat(tickets[i].split("*")[1]);

                if (spinNum[0].number.toString() == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].number * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].number * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (eachNumber.includes(",")) {
                  if (
                    eachNumber.split(",")[0].trim() ==
                      spinNum[0].number.toString() ||
                    eachNumber.split(",")[1].trim() ==
                      spinNum[0].number.toString()
                  ) {
                    totalPossibleWinMoney =
                      totalPossibleWinMoney +
                      (rowOut2[0].number / 2) * eachAmount;

                    countWin++;

                    // this.payableList.push(
                    //   tickets[i] +
                    //     " = " +
                    //     (
                    //       (rowOut2[0].number / 2) *
                    //       eachAmount
                    //     ).toString() +
                    //     " Birr Won"
                    // );
                  }
                } else if (spinNum[0].color == eachNumber) {
                  if (eachNumber == "0 Green") {
                    totalPossibleWinMoney =
                      totalPossibleWinMoney + rowOut2[0].number * eachAmount;

                    countWin++;

                    // this.payableList.push(
                    //   tickets[i] +
                    //     " = " +
                    //     (rowOut2[0].number * eachAmount).toString() +
                    //     " Birr Won"
                    // );
                  } else {
                    totalPossibleWinMoney =
                      totalPossibleWinMoney + rowOut2[0].high_low * eachAmount;
                    countWin++;

                    // this.payableList.push(
                    //   tickets[i] +
                    //     " = " +
                    //     (rowOut2[0].high_low * eachAmount).toString() +
                    //     " Birr Won"
                    // );
                  }
                } else if (spinNum[0].sector == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].sector * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].sector * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (spinNum[0].even_odd == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].even_odd * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].even_odd * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (spinNum[0].high_low == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].high_low * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].high_low * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (
                  spinNum[0].neighbors.split(",")[0].trim() == eachNumber ||
                  spinNum[0].neighbors.split(",")[1].trim() == eachNumber ||
                  spinNum[0].neighbors.split(",")[2].trim() == eachNumber ||
                  spinNum[0].neighbors.split(",")[3].trim() == eachNumber ||
                  spinNum[0].neighbors.split(",")[4].trim() == eachNumber
                ) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].neighbors * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].neighbors * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (spinNum[0].twins == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].twins * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].twins * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (spinNum[0].dozen == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].dozen * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].dozen * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (eachNumber.includes("Final")) {
                  if (spinNum[0].final.includes(eachNumber)) {
                    totalPossibleWinMoney =
                      totalPossibleWinMoney + rowOut2[0].final * eachAmount;

                    countWin++;

                    // this.payableList.push(
                    //   tickets[i] +
                    //     " = " +
                    //     (rowOut2[0].final * eachAmount).toString() +
                    //     " Birr Won"
                    // );
                  }
                } else if (spinNum[0].mirror == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney + rowOut2[0].mirror * eachAmount;
                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (rowOut2[0].mirror * eachAmount).toString() +
                  //     " Birr Won"
                  // );
                } else if (spinNum[0].combination == eachNumber) {
                  totalPossibleWinMoney =
                    totalPossibleWinMoney +
                    rowOut2[0].high_low_color * eachAmount;

                  countWin++;

                  // this.payableList.push(
                  //   tickets[i] +
                  //     " = " +
                  //     (
                  //       rowOut2[0].high_low_color * eachAmount
                  //     ).toString() +
                  //     " Birr Won"
                  // );
                }

                // if (countWin == 0)
                //   this.payableList.push(tickets[i] + " = Loss ");
              }
            }

            this.spinTotalWinMoneyForOneUser = totalPossibleWinMoney;
          } else alert("Ticket not found");
        } catch (err) {
          alert(err);
        }
        this.spinTicketSearchLoading = false;
      } else alert("Please enter user id or scan bar code");
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.odds = oddsResult.data.data.getOdds;
      } catch (err) {}

      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
                getOdds{
                  	odds_id
		                number
		                color
		                mirror
		                twins
		                final
		                dozen

		                sector
		                high_low_color
		                even_odd
		                high_low
		                neighbors

                }

              }
              `,
          },
        });

        this.spinOdds = oddsResult.data.data.getOdds;
        // eslint-disable-next-line no-empty
      } catch (err) {}

      try {
        var spinnumbersResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
                getSpinNumbers{
                   spin_number_id
                   number                 
                   neighbors
                    }
              }
              `,
          },
        });

        this.spinNumbers = spinnumbersResult.data.data.getSpinNumbers;
      } catch (err) {}
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      if (Number(Date.now() - this.$store.state.sessionTime) > 8 * 60 * 1000) {
        this.$store.dispatch("loginPartner", "");
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch("sessionTime", Date.now());
      }
      this.getOdds();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
