<template>
  <v-app>
    <navBar />
    <v-main class="00ff1b2a3d" id="body">
      <router-view />
    </v-main>
    <v-footer
      id="footer"
      style="border-top: 1px solid cadetblue; font-size: 12px"
      class="white--text"
    >
      <v-col class="text-center">
        <span v-if="2022 == new Date().getFullYear()">
          &copy;{{ new Date().getFullYear() }} Apice virtual. All Rights
          Reserved</span
        >

        <span v-else>
          &copy;2022 - {{ new Date().getFullYear() }} Apice virtual. All Rights
          Reserved</span
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import navBar from "./components/navBar.vue";

export default {
  components: {
    navBar,
  },
  data: () => ({
    //
  }),
};
</script>
<style scoped>
#body,
#footer {
  background-color: rgba(27, 42, 61, 255);
}
</style>
